import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import SelectSearch, { fuzzySearch } from "react-select-search";

class BulkDownloadUnits extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    convertToCSV(objArray) {
        var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";

        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";

                line += '"' + array[i][index] + '"';
            }

            str += line + "\r\n";
        }

        return str;
    }
    exportCSVFile(headers, items, fileTitle) {
        
        if (headers !== items[0]) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = fileTitle + ".csv" || "export.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }
    render() {
        if (this.props.unitsArrayAll !== undefined) {
            var headers = {
                unitID: "Unit ID*",
                unitBuilding: "Building*",
                unitFloor: "Floor*",
                purpose:
                    "Purpose (wiredunit or localwirelessonly)*",
                AddressLine1: "Address 1*",
                SubscriberAddress2: "Address 2",
                SubscriberCity: "City*",
                SubscriberState: "State*",
                SubscriberPostalCode: "Postal Code*",
                SubscriberCountry: "Country*",
                VLAN: "VLAN",
                // ServicePlanID: "Service Plan ID",
                unitIDInternal: "Unit Internal ID",
                externalVenueUnitID: "Unit External ID",
                ShoppingCartPriceAdjustment: "Shopping Cart Price Adjustment"
            };
            var itemsFormatted = [];
            for (const key of this.props.unitsArrayAll) {
                itemsFormatted.push({
                    unitID: key["UnitID"],
                    unitBuilding: key["Building"],
                    unitFloor: key["Floor"],
                    purpose: key["Purpose"],
                    address1: key["address1"],
                    address2: key["address2"],
                    city: key["city"],
                    state: key["state"],
                    postalcode: key["postalcode"],
                    country: key["country"],
                    vlan: key["vlan"],
                    // serviceplanid: key["servicePlanID"],
                    unitID_internal: key["unitID_internal"],
                    externalVenueUnitID: key["externalVenueUnitID"] === undefined ? "" : key["externalVenueUnitID"],
                    ShoppingCartPriceAdjustment:
                        key["shoppingCartPriceAdjustment"],
                });
            }
            var fileTitle = sessionStorage.getItem("venueName"); // or 'my-unique-title'
        }
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleDownloadUnits}>
                    <h4>Bulk Download Units</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="venueSelector">
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="4">
                                <h5>Venue Name:</h5>
                            </MDBCol>
                            <MDBCol col="6" sm="12" lg="8">
                            <SelectSearch
                              id="selectVenue"
                              search={true}
                              filterOptions={fuzzySearch}
                              options={this.props.optionTemplateVenues}
                              isOptionSelected={true}
                              inputId="venueArray"
                              classNamePrefix="venue-select"
                              placeholder="Select..."
                              onChange={this.props.handleSelectVenue}
                              value={
                                this.props.clearDropDown === true
                                  ? ""
                                  : this.props.venueID
                              }
                            />
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <div className="text-right submitButton">
                        <MDBBtn color="danger" disabled={this.props.clearDropDown} onClick={() => this.exportCSVFile(headers, itemsFormatted, fileTitle)}>
                            {
                                this.props.loadingVenueDetails === true ? (
                                                    <div
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">
                                                            Loading...
                                                        </span>
                                                    </div>
                                                ) : (
                                                    "Export Units"
                                                )
                                            }
                        </MDBBtn>
                        <MDBBtn color="primary" onClick={this.props.toggleDownloadUnits}>
                            Close
                        </MDBBtn>
                    </div>
                </MDBModalBody>
            </>
        );
    }
}

export default BulkDownloadUnits;
