/* Find Deals screen */
import React from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from "mdbreact";

import CheckoutForm from "../../../brands/gigstreem/components/Upgrade/CheckOutForm";

import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

import { useHistory } from "react-router-dom";

import brandsData from "../../../brands/common/brands.json";

let BrandName;

brandsData.forEach(({ name, url }) => {
    if (url.includes(window.location.hostname)) {
        BrandName = name;
    }
});

export default function App() {
    const appearance = {
        theme: "stripe",
    };
    const clientSecret = sessionStorage.getItem("clientSecret");
    const options = {
        clientSecret,
        appearance,
    };

    const history = useHistory();

    function handleSubmit() {
        history.push("/myaccount");
        sessionStorage.removeItem("billingCyclePrice");
        sessionStorage.removeItem("billingCycleRecurrence");
        sessionStorage.removeItem("clientSecret");
        sessionStorage.removeItem("daysofservice");
        sessionStorage.removeItem("servicePlanID");
        sessionStorage.removeItem("servicePlanName");
        sessionStorage.removeItem("stripePriceID");
        sessionStorage.removeItem("stripePromise");
    }
    return (
        <>
            <div className="CreateAccount">
                <MDBModal
                    isOpen={true}
                    size="lg"
                    className="form"
                    centered
                    overflowScroll
                >
                    <MDBModalHeader toggle={handleSubmit}>
                        Congratulations!
                    </MDBModalHeader>
                    <MDBModalBody className="successModal" id="faq">
                        <p>
                            Your {sessionStorage.getItem("brand")} Home Wifi is
                            upgraded.
                        </p>
                    </MDBModalBody>
                </MDBModal>
                <MDBContainer className="Form" fluid>
                    <MDBRow className="g-0 m-0">
                        <MDBCol sm="12" md="12" lg="12" className="ml-auto p-0">
                            <div className="topBanner">
                                <h2>Order Complete</h2>
                            </div>
                            <div className="FormContainer">
                                <div className="loginDisclosure">
                                    <div className="App">
                                        {clientSecret &&
                                            sessionStorage.getItem(
                                                "stripePromise"
                                            ) && (
                                                <Elements
                                                    options={options}
                                                    stripe={loadStripe(
                                                        sessionStorage.getItem(
                                                            "stripePromise"
                                                        )
                                                    )}
                                                >
                                                    <CheckoutForm />
                                                </Elements>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}
